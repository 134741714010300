import React, { FC } from "react";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Wrap,
  WrapItem,
  Text,
  Image,
  Link as _Link,
  useColorMode,
} from "@chakra-ui/react";
import { StarIcon } from '@chakra-ui/icons';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import Link from "next/link";
import IProject from "types/project";
import { IoMdArrowRoundForward } from "react-icons/io";

const Testimonials: FC = () => {
  const { colorMode } = useColorMode();
  const cardBgColor = { light: "white", dark: "gray.900" };
  const cardColor = { light: "gray.900", dark: "white" };
  const clutchLogoComponent = () => {
    if (colorMode === 'light') {
      return <img width="80px" src="/images/common/clutch.svg" />
    } else {
      return <img width="80px" src="/images/common/clutch_white.svg" />
    }
  }

  const quotes = [
    { title: "Clever Brainz is a top programmer", provider: "Professor of Australian Catholic University, Joseph Ciarrochi", description: "The one-man team continues to amaze the client with high-quality deliverables. A user-friendly and reliable mobile app has been made and is now awaiting its official product launch, thanks to Clever Brainz's field expertise and dedication." },
    { title: "We came in under budget, and I was impressed with the hours that they’d worked", provider: "CEO of Vacay, Carley Wetzig", description: "Clever Brainz translated the initial idea into a working product, solving complex technical challenges that would’ve taken a year to do in two months. The quality of their work rivals that of top-level engineers without the inaccessible price point. The team is also committed to hitting deadlines." },
    { title: "Their communication style was impressive", provider: "Co-Owner of Unicon Construction Solutions, Or Palmor", description: "The mobile app works as intended, thanks to Clever Brainz. The client found the team's project management and communication style to be highly effective." },
    { title: "Easy to get in touch with, fast communication", provider: "Managing Director of Russell Jones Mfg. Inc, Dan Defilla", description: "Clever Brainz does a great job with every project we give them. We can always count of them to get the job done on time." },
    { title: "Fast and efficient to work with ", provider: "Owner of Templific, Michelle Jones", description: "Clever Brainz took on challenges/finding solutions and coded designs/interactions to spec." }
  ];

  const headingNode = () => {
    return (
      <Box pb={4} d="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="xl">
          Testimonials
        </Heading>
      </Box>
    );
  };

  const titleNode = (title: string) => {
    return (
      <Heading as="h3" size="md">
        {title}
      </Heading>
    );
  };

  const providerNode = (provider: string) => {
    return <Text fontSize="sm">{provider}</Text>;
  };

  const descriptionNode = (description: string) => {
    return <Text fontSize="sm">{description}</Text>;
  };


  return (
    <Stack spacing={4}>
      {headingNode()}
      <Stack spacing={8}>
        <Carousel animation="slide" interval={5000} >
          {quotes.map((quote, index: number) => {
            return (
              <Box
                key={index}
                bg={cardBgColor[colorMode]}
                color={cardColor[colorMode]}
                p={8}
                rounded="md"
                shadow="md"
              >
                <Stack spacing={2}>
                  {titleNode(quote.title)}
                  {providerNode(quote.provider)}
                  {descriptionNode(quote.description)}
                </Stack>
              </Box>
            );
          })}
        </Carousel>
      </Stack>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Wrap align="center" justify="center" >
          <WrapItem>
          <a target="_blank" href="https://www.goodfirms.co/company/clever-brainz">
            <Box display="flex">
              <Box>
                <Text fontSize="lg" mt="1" fontWeight={700}>EXCELLENT</Text>
                <Text fontSize="xs" letterSpacing={-1} color="#959aa1" >BASED ON 5 REVIEWS</Text>
              </Box>
              <Box ml="2">
                <Box ml={-1} >
                {Array(5)
                .fill("")
                .map((_, i) => (
                  <StarIcon
                    w={25}
                    h={25}
                    key={i}
                    color="#ffba00"
                  />
                ))}
                </Box>
                <Image width="130px" src="/images/common/goodfirm.png" />
              </Box>
            </Box>
          </a>
          </WrapItem>
          <WrapItem>
            <Box w="180px" h="10px"></Box>
          </WrapItem>
          <WrapItem>
          <a target="_blank" href="https://clutch.co/profile/clever-brainz">
            <Box display="flex">
              <Box>
                <Text fontSize="xs" >REVIEWED ON</Text>
                {clutchLogoComponent()}
              </Box>
              <Box ml="2">
                <Box mt={-1}>
                {Array(5)
                .fill("")
                .map((_, i) => (
                  <StarIcon
                    key={i}
                    color="#ff3d2e"
                  />
                ))}
                </Box>
                <Text mt="1" fontSize="sm">3 REVIEWS</Text>
              </Box>
            </Box>
          </a>
          </WrapItem>
        </Wrap>
      </Box>
    </Stack>
  );
};

export default Testimonials;
